import React from "react";
import cn from 'classnames';
import { createTrackingEvent } from "lib/tracking";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import * as LinkButtonStyling from "./LinkButton.module.scss";

const ButtonGenerator = ({
  isExternal,
  href,
  className,
  onClick,
  buttonClassName,
  underlineText,
  text,
  buttonSize,
  ...attrs
}) => {
  const tag = isExternal ? "a" : Link;
  const to = isExternal ? href : `/${href}`;
  return (
    <div className={cn(LinkButtonStyling.buttonWrapper, className)}>
      <Button
        tag={tag}
        className={buttonClassName}
        to={to}
        href={to}
        onClick={onClick}
        {...attrs}
      >
        {text}
      </Button>
      {underlineText && (
        <p
          className={`text3 ${LinkButtonStyling.underline} ${
            LinkButtonStyling[`underline${buttonSize}`]
          }`}
        >
          {underlineText}
        </p>
      )}
    </div>
  );
};

const LinkButton = ({
  type,
  text,
  isExternal,
  internalLink,
  externalLink,
  buttonSize,
  className = "",
  textLinkClassName,
  onClick,
  trackingName,
  underlineText,
  buttonClassName
}) => {
  if (!externalLink && !internalLink) {
    return null;
  }
  const trackClick = () => {
    createTrackingEvent(
      `Storefront - ${trackingName} CTA Click`,
      {},
      "Click",
      trackingName
    );
  };

  const href = isExternal ? externalLink : internalLink.slug;

  switch (type) {
    case "Text link":
      // Text links skips button component
      return (
        <>
          {isExternal ? (
            <a
              href={externalLink}
              className={textLinkClassName}
              {...(onClick && { onClick: onClick })}
            >
              {text}
            </a>
          ) : (
            <Link
              to={`/${internalLink.slug}`}
              className={textLinkClassName}
              {...(onClick && { onClick: onClick })}
            >
              {text}
            </Link>
          )}
        </>
      );
    default:
      return (
        <ButtonGenerator
          primary={type === "Primary button"}
          secondary={type === "Secondary button"}
          destructive={type === "Destructive button"}
          underlineText={underlineText}
          buttonSize={buttonSize}
          buttonClassName={buttonClassName}
          isExternal={isExternal}
          href={href}
          className={`btn--${buttonSize} ${className}`}
          onClick={() => {
            onClick && onClick.apply();
            trackingName && trackClick();
          }}
          text={text}
        />
      );
  }
};

export default LinkButton;

LinkButton.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string.isRequired,
    buttonSize: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isExternal: PropTypes.bool.isRequired,
    internalLink: PropTypes.string,
    externalLink: PropTypes.string,
    textLinkClassName: PropTypes.string,
    onClick: PropTypes.func,
    underlineText: PropTypes.string,
  }),
};
